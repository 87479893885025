import request from '@/utils/request'

// export function get(id) {
//   return request({
//     url: "api/deposit/" + id,
//     method: "get"
//   })
// }

export function add(data) {
  return request({
    url: 'api/deposit/ent',
    method: 'post',
    data
  })
}


export function edit(data) {
  return request({
    url: 'api/deposit',
    method: 'put',
    data
  })
}

export function del(id) {
  return request({
    url: 'api/deposit/' + id,
    method: 'delete'
  })
}
export function pay(data) {
    return request({
      url: 'api/deposit/payOnline',
      method: 'post',
      data
    })
  }

